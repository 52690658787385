<template>
  <div class="works">
    <left-menu current="demo-list" />
    <div class="main">

      <el-row>
        <el-col :span="12">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item to="/demo-list">词曲作品列表</el-breadcrumb-item>
            <el-breadcrumb-item>作品详情</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
        <el-col :span="12" style="text-align: right"><el-link type="primary" @click="$router.back()"> &lt;返回</el-link></el-col>
      </el-row>

      <el-divider></el-divider>

      <div class="title">
        作品详情
      </div>

      <el-row :gutter="10">
        <el-col :span="15">
          <div style="display: flex;">
            <div class="cover">
              <el-image :src="!!this.demo.picturePath ? this.demo.picturePath : './assets/defaultCover.png'" style="width: 160px;height: 160px;"></el-image>
            </div>
            <div class="demo-info">
              <div class="title">{{demo.name}}</div>
              <div class="info-item">
                <span class="label">{{demo.type === 0 ? '词曲价格' : '曲价格'}}</span>
                <span style="font-size: 14px;color: #FF004D;font-weight: 100">￥</span>
                <span style="font-size: 20px;color: #FF004D;font-weight: 600">{{demo.lyricsPrice + demo.opernPrice}}</span>
                <span v-if="demo.type === 0" style="font-size: 14px;font-weight: 400;color: #666666;margin-left: 8px">
                  [词￥{{demo.lyricsPrice}}+曲￥{{demo.opernPrice}}]
                </span>
              </div>
                <div class="info-item" v-if="demo.type === 0" >
                <span class="label">作词</span>
                <span class="text">{{demo.lyricist}}</span>
              </div>
               <div class="info-item" v-if="demo.type === 0" >
                <span class="label">作曲</span>
                <span class="text">{{demo.composer}}</span>
              </div>
              <div class="info-item"  v-if="demo.type !== 0" >
                <span class="label">曲作者</span>
                <span class="text">{{demo.composer}}</span>
              </div>
              <div class="info-item">
                <span class="label">标　　签</span>
                <span v-if="demo.tag"><el-tag size="mini" style="margin-right: 6px" v-for="t in demo.tag.split(',')" :key="t">{{ t }}</el-tag></span>
              </div>
            </div>
          </div>

          <div class="status">
            <div>
              <span class="label">状　　态</span>
              <span style="color: #FF004D;font-size: 12px">
             {{ getStatusByTotalStatus(demo.totalStatus)}}
           </span>
            </div>
            <div>
              <span class="label">提交时间</span>
              <span style="color: #666666;font-size: 12px">{{ $timestampToString(demo.submitTime) }}</span>
            </div>
          </div>

          <div class="player">
            <audio controls="controls" v-if="demo.audioPath" style="width: 420px">
              <source :src="demo.audioPath" type="audio/mp3" />
              Your browser does not support this audio format.
            </audio>
          </div>

          <h4>歌曲简介</h4>
          <div class="description">
            {{ demo.remark || '暂无介绍' }}
          </div>

          <div v-if="demo.totalStatus === 7">
            <h4>当前合同信息</h4>
            <div class="agreement">
              <span class="label">合同编号</span>{{ contract.id }}
            </div>
            <div class="agreement">
<!--              1-新增 2-待他人签署 3-签署完成 4-已取消-->
              <span class="label">合同状态</span>{{ ['', '新增', '待他人签署', '签署完成', '已取消'][this.contract.status] }}
            </div>
            <div class="agreement">
              <span class="label">合同信息</span>
              <el-link type="primary" @click="$router.push('/contract/' + id)">{{ contract.title }}</el-link>
            </div>
          </div>

        </el-col>
        <el-col :span="9">
         <div class="lyric-box">
           <div class="lyric">
             <pre style="margin: 0; white-space:pre-line">{{lyrics}}</pre>
           </div>
           <div class="lyric-top"></div>
           <div class="lyric-bottom"></div>
         </div>
        </el-col>

        <el-col>
          <h4>创作说明</h4>
          <!--          <el-link type="primary" :href="demo.creationPath" v-if="!!demo.creationPath">-->
          <!--            <img src="../assets/file.png" alt="file"> {{demo.creationPath.split('/').reverse()[0]}}-->
          <!--          </el-link>-->
          <div v-if="!demo.creationData" class="description">暂无说明</div>
          <div v-else style="width: 868px;max-width: 868px;height: 200px;max-height: 400px;position: relative; overflow: auto;margin-bottom: 40px" v-html="demo.creationData"></div>
        </el-col>

        <el-col>
          <div style="text-align: center">
            <el-button class="big-button" type="info" @click="$router.back()">返回</el-button>
            <el-button class="big-button" v-show="demo.totalStatus === 1" @click="handleRevoke(id)" type="primary">撤销审核</el-button>
            <el-button class="big-button" v-show="demo.totalStatus === 4" @click="handleOffSell(id)" type="primary">申请下架</el-button>
          </div>
        </el-col>
      </el-row>
    </div>

    <!--  todo 封装  -->
    <el-dialog
        title="申请下架"
        :visible.sync="offSell.dialogVisible"
        :show-close="false"
        center
        :v-loading="offSell.loading"
        width="480px">
      <el-input v-model="offSell.reason" placeholder="输入下架原因"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="offSell.dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleOffSellSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import LeftMenu from '../components/LeftMenu.vue'
import DemoDownload from '../assets/demo-download.png'
import {getStatusByTotalStatus} from "../utils/utils"
import message from "../utils/message"
import api from "../api"

export default {
  name: "DemoView",
  props: ['id'],
  components: {
    LeftMenu
  },
  data(){
    return {
      demoDownload: DemoDownload,
      demo: {
        tag: '',
        creationData: ''
      },
      lyrics: '',
      offSell: {
        loading: false,
        dialogVisible: false,
        reason: '',
        id: null
      },
      contract: {
        id: '',
        contractFile: '',
        title: '',
        // 1-新增 2-待他人签署 3-签署完成 4-已取消
        status: 0
      }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData(){
      this.$get('/api/demo/detail?songId=' + this.id).then(r => {
        this.demo = r.data
        this.getLyrics()
        if (this.demo.totalStatus === 7) {
          // 出售中
          this.getContract(this.id)
        }
      }).catch(e => {
        console.error(e)
      })
    },
    getLyrics(){
      this.$get(this.demo.lyricsPath).then(r => {
        this.lyrics = r
      }).catch(e => {
        console.error(e)
      })
    },
    getContract(id){
      api.getContractById(id).then(r => {
        // console.log(r)
        if (r.code === 0 && r.data !== null) {
          this.contract = r.data
        }
      }).catch(e => {console.error(e)})
    },
    getStatusByTotalStatus(id){
      return getStatusByTotalStatus(id)
    },
    handleRevoke(id){
      // todo 封装
      this.$get('/api/demo/revoke?songId=' + id).then(() => {
        this.$router.replace('/demo/' + id)
      }).catch(e => {console.error(e)})
    },
    handleOffSell(id){
      // todo 封装
      this.offSell.id = id
      this.offSell.dialogVisible = true
    },
    handleOffSellSubmit(){
      // todo 封装
      this.offSell.loading = true
      this.$post('/api/demo/offSell', {
        songId: this.offSell.id,
        reason: this.offSell.reason
      }).then(r => {
        this.offSell.loading = false
        this.offSell.dialogVisible = false
        if(r.code === 0){
          message('下架成功')
          this.offSell.reason = ''
        }
        this.getData()
      }).catch(e => {
        this.offSell.loading = false
        console.error(e)
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import '../styles/works.scss';
.main>.title{
  font-size: 26px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 30px;
}
.lyric-box{
  position: relative;
}
.lyric{
  margin: 0;
  text-align: center;
  height: 490px;
  background: #F8F8F8;
  border: 1px solid #E8E8E8;
  overflow-y: scroll;
  overflow-x: hidden;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 28px;
  padding: 14px;
  position: relative;
}
.lyric-top{
  position: absolute;
  top: 1px;
  left: 1px;
  right: 1px;
  height: 50px;
  background-image: linear-gradient(#F8F8F8, transparent);
}
.lyric-bottom{
  position: absolute;
  bottom: 1px;
  left: 1px;
  right: 1px;
  height: 50px;
  background-image: linear-gradient(transparent, #F8F8F8);
}

.cover{
  width: 160px;
  height: 160px;
  margin-right: 20px;
}
.demo-info{
  .title{
    font-size: 20px;
    font-weight: 600;
    color: #333333;
    line-height: 28px;
  }
  .info-item{
    font-size: 14px;
    font-weight: 400;
    line-height: 32px;
    .label{
      display: inline-block;
      text-align-last: justify;
      width: 56px;
      color: #999999;
      margin-right: 18px;
    }
    .text{
      color: #333333;
    }
  }
}
.status{
  padding: 9px 16px;
  width: 388px;
  background: #F8F8F8;
  margin-top: 20px;
  margin-bottom: 20px;
  .label{
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    line-height: 23px;
    margin-right: 18px;
  }
}
.player{
  width: 420px;
  height: 40px;
  margin-bottom: 40px;
}
.description{
  overflow-y: auto;
  width: 420px;
  min-height: 80px;
  max-height: 110px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}
::v-deep .el-link--inner{
  display: flex;
}
.agreement{
  line-height: 26px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  .label{
    color: #999999;
    margin-right: 20px;
  }
}
</style>
